
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
	path: '/',
	meta: {
		title: '智慧安保数据看板',
		noCache: true
	},
	name: 'nyzn',
	component: () => import("../views/sxdScreen.vue")
},
	{
		path: '/zdwz',
		meta: {
			title: '智慧安保支队实时看板',
			noCache: true
		},
		name: 'zdwz',
		component: () => import("../views/zd/postion.vue")
	},
	{
		path: '/fjwz',
		meta: {
			title: '智慧安保分局实时看板',
			noCache: true
		},
		name: 'fjwz',
		component: () => import("../views/fj/postion.vue")
	},
	{
		path: '/pcswz',
		meta: {
			title: '智慧安保派出所实时看板',
			noCache: true
		},
		name: 'pcswz',
		component: () => import("../views/pcs/postion.vue")
	},
	{
		path: '/gswz',
		meta: {
			title: '智慧安保公司实时看板',
			noCache: true
		},
		name: 'gswz',
		component: () => import("../views/gs/postion.vue")
	},
	{
		path: '/zd',
		meta: {
			title: '智慧安保支队数据看板',
			noCache: true
		},
		name: 'nyzn',
		component: () => import("../views/zd/index.vue")
	},
	{
		path: '/fj',
		meta: {
			title: '智慧安保分局数据看板',
			noCache: true
		},
		name: 'nyzn',
		component: () => import("../views/fj/index.vue")
	},
	{
		path: '/pcs',
		meta: {
			title: '智慧安保派出所数据看板',
			noCache: true
		},
		name: 'nyzn',
		component: () => import("../views/pcs/index.vue")
	},
	{
		path: '/gs',
		meta: {
			title: '智慧安保公司数据看板',
			noCache: true
		},
		name: 'nyzn',
		component: () => import("../views/gs/index.vue")
	},
	{
	path: '/alarm',
	meta: {
		title: '智慧安保数据看板',
		noCache: true
	},
	name: 'alarm',
	component: () => import("../views/alarm.vue")
}, 
]
const router = new VueRouter({
	mode: "history",
	routes
})
router.beforeEach((to, from, next) => {
	//beforeEach是router的钩子函数，在进入路由前执行
	if (to.meta.title) {
		//判断是否有标题
		// console.log(to.meta.title)
		document.title = to.meta.title
	} else {
		document.title = '智慧安保数据看板'
	}
	next()
})
export default router
